import request from '@/utils/request'

export function sendApi(data) {
  return request({
    url: '/api/app/messages/send',
    method: 'post',
    data
  })
}

export function manySendApi(data) {
  return request({
    url: '/api/app/messages/manySend',
    method: 'post',
    data
  })
}

export function cancelAutoSendMessageApi(data) {
  return request({
    url: '/api/app/messages/cancelAutoSendMessage',
    method: 'post',
    data
  })
}


export function getAutoListApi(data) {
  return request({
    url: '/api/app/messages/getAutoList',
    method: 'post',
    data
  })
}



export function getCustomerMessageApi(data) {
  return request({
    url: '/api/app/messages/getCustomerMessage',
    method: 'post',
    data
  })
}

