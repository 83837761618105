import request from '@/utils/request'

export function infoApi(data) {
  return request({
    url: '/api/app/phone_number/info',
    method: 'post',
    data
  })
}

export function bindWebSocketApi(data) {
  return request({
    url: '/api/app/auth/bindWebSocket',
    method: 'post',
    data
  })
}

export function refreshApi(data) {
  return request({
    url: '/api/app/auth/refresh',
    method: 'post',
    data
  })
}


export function delCustomerAndMessageApi(data) {
  return request({
    url: '/api/app/phone_number/del_customer_and_message',
    method: 'post',
    data
  })
}



