<template>
 <div class='login-container'>
  <el-card class="box-card">
   <div class="title-container">
    <p class="title">六神跨境电商营销系统</p >
   </div>
   <el-form :model="loginForm" ref="loginForm">
    <el-form-item prop="username" :rules="[
                      { required: true, message: '用户名不能为空'},
                      ]">
     <el-input v-model="loginForm.username" placeholder="用户名" name="username" type="text" tabindex="1"
      auto-complete="on" />
    </el-form-item>
    <el-form-item prop="password" :rules="[
                      { required: true, message: '密码不能为空'},
                      ]">
     <el-input ref="password" v-model="loginForm.password" type="password" placeholder="密码"
      name="password" tabindex="2" auto-complete="on" />
    </el-form-item>
    <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;"
     @click.native.prevent="handleLogin">登 录</el-button>
   </el-form>
  </el-card>
 </div>
</template>
<script>
 import axios from 'axios'
 export default {
  name: 'userLogin',
  data() {
   return {
    loading: false,
    loginForm: {
     username: '',
     password: ''
    }
   }
  },
  mounted(){
   
  },
  methods: {
   handleLogin() {
    
    // 非空验证
    if (!this.loginForm.username || !this.loginForm.password) {
     this.$message.error('用户名和密码不能为空');
     return;
    }
    axios.post(process.env.VUE_APP_BASE_API + 'api/app/auth/index', this.loginForm).then(res => {
     if (res.data.code == 200) {
      localStorage.setItem('Token', res.data.data.token)
      localStorage.setItem('refresh_token',res.data.data.refresh_token)

      setTimeout(() => {
       location.reload()
      }, 10)
      this.$router.push({
       path: '/index'
      })
     } else {

      this.$message.error(res.data.message);
     }
    })

   }
  }
 }
</script>

<style scoped>
 .login-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #7B68EE;
  overflow: hidden;
 }

 .title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 10px 0;
  /* 调整这里的 margin 值 */

 }

 .title {
  font-weight: bold;
  font-size: 30px;
 }

 .box-card {
  padding-top: 0px;
  width: 480px;
  height: 320px;

 }

 .svg-container {
  padding: 6px 5px 6px 15px;
  color: $dark_gray;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
 }
</style>