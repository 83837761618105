import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import LemonIMUI from 'lemon-imui';
import 'lemon-imui/dist/index.css';
Vue.use(ElementUI,{size:"mini"});

Vue.use(LemonIMUI);
Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
