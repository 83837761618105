import request from '@/utils/request'

export function createApi(data) {
  return request({
    url: '/api/app/customer/create',
    method: 'post',
    data
  })
}

export function editApi(data) {
  return request({
    url: '/api/app/customer/edit',
    method: 'post',
    data
  })
}
export function clearRedDotApi(data) {
  return request({
    url: '/api/app/customer/clearRedDot',
    method: 'post',
    data
  })
}


export function getListApi(data) {
  return request({
    url: '/api/app/customer/getList',
    method: 'post',
    data
  })
}

export function getGroupListApi(data) {
  return request({
    url: '/api/app/group/getList',
    method: 'post',
    data
  })
}

export function setGroupApi(data) {
  return request({
    url: '/api/app/customer/setGroup',
    method: 'post',
    data
  })
}

export function delGroupApi(data) {
  return request({
    url: '/api/app/group/del',
    method: 'post',
    data
  })
}





