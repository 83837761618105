import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import index from '@/components/index'

import uim from '@/components/uim'


import login from '@/components/login'


export default new VueRouter({
    routes: [
      {
            path: '/',
            name: 'login',
            component: login
      },
      {
        path: '/index',
        name: 'index',
        component: index 
      },
      {
        path: '/uim',
        name: 'uim',
        component: uim 
      }
    ]
})