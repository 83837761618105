<template>
  <div class="auto-generate">
    <div class="box">
      <div class="phone-number-box">
        <div class="clearfix">
            <span>目的地手机号：一行一个</span>
        </div>
        <el-card class="box-card"  style="overflow:auto" >
          <div v-for="(o,i) in phone_number" :key="i" class="text item">
            {{ o }}
          </div>
        </el-card>
        <div class="btn">
        <el-upload
          class="upload-demo"
          ref="upload"
          action=""
          :http-request="uploadPhone"
          :show-file-list="false"
          :auto-upload="true">
          <el-button slot="trigger" type="text" size="medium" >上传手机号：一行一个</el-button>
        </el-upload>
          <el-button type="text" size="medium"></el-button>
        </div>
      </div>
      <div class="messages">
        <div class="clearfix">
            <span>营销信息内容：一行一个</span>
        </div>
        <el-card class="box-card" style="overflow:auto" >
          <div v-for="o in message" :key="o" class="text item">
            {{o }}
          </div>
        </el-card>
        <div class="btn">
          <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :http-request="uploadMessage"
            :show-file-list="false"
            :auto-upload="true">
            <el-button slot="trigger" type="text" size="medium" >上传营销信息内容：一行一个</el-button>
          </el-upload>
          
        </div>
      </div>

    </div>
    <div class='below-btn'>
      <el-checkbox v-model="is_check" size="medium">是否开启相似度检测，如果开启后，至少上传6条相似度小于80%的营销信息</el-checkbox>
      <div  style="margin-top:40px;">
        <el-button @click="downloadfile" type="primary"size="medium" >生成并下载</el-button>
        <el-button @click="()=>{ this.phone_number = []; this.message = []}" type="danger"size="medium" >全部清除</el-button>
      
      </div>
      
    </div>
    
    
  </div>
</template>
<script>
import {generateRandomTextFromArray, shuffleArray} from '@/utils/index'
import {manySendApi,getAutoListApi, cancelAutoSendMessageApi} from '@/api/messages'
import Pagination from "@/components/Pagination";
import axios from 'axios'
export default {
  name: 'autosend',
  data() {
   return {
    is_check:true,
    phone_number:[],
    message:[]
   }
  },
  mounted(){
    this.phone_number = []
    this.message = []
  },
  methods: {
    downloadfile(){
      
      if(this.phone_number.length == 0){
        this.$message.error('号码不能为空')
        return
      }
      if(this.message.length == 0){
        this.$message.error('营销信息不能为空')
        return
      }
      if(this.is_check && this.message.length < 6){
          this.$message.error('营销信息不能小于六条')
        return
      }
      let data =  generateRandomTextFromArray(this.message,this.phone_number.length,this.is_check)
      let phone_number =    this.phone_number

     shuffleArray(phone_number)
     
      var content = ''
      for(let i = 0 ;i< phone_number.length; i++){
        content += this.phone_number[i] +'----'+data[i]+'\r\n'
      }
      const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
      const fileName = "营销短信.txt";
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    uploadMessage(params){
      const readFile = new FileReader()
        readFile.readAsText(params.file, 'UTF-8')
        readFile.onload = (e) => {
          this.textarea = e.target.result;
          let arr = this.textarea.split(/[(\r\n)\r\n]+/)
          
          for(let i=0; i < arr.length; i++){
            if(arr[i] != ''){
              this.message.push(arr[i])
            }
            
           
          }
          params.onProgress({ percent: 100 }) // 调用 element ui 进度条
          params.onSuccess() // 调用 element ui 上传成功方法
        }


    },
    uploadPhone(params){
      const readFile = new FileReader()
        readFile.readAsText(params.file, 'UTF-8')
        readFile.onload = (e) => {
          this.textarea = e.target.result;
          let arr = this.textarea.split(/[(\r\n)\r\n]+/)

          for(let i=0; i < arr.length; i++){
            let phone_number =  arr[i]
            let base_phone_number = arr[i]
            phone_number =  this.formatUSPhoneNumber(phone_number)
            if(phone_number == null){
              this.$message.error("号码不正确")
              return
            }
           
          
            const reg = /^(\(?\d{3}\)?-?)?(\d{3}-?\d{4})$/
            
            if(!reg.test(phone_number)){
              this.$message.error('手机号码格式错误')
              return;
            }
            this.phone_number.push(base_phone_number)
          }

         
 
          params.onProgress({ percent: 100 }) // 调用 element ui 进度条
          params.onSuccess() // 调用 element ui 上传成功方法
        }
      

    },
    formatUSPhoneNumber(phoneNumber) {
      // 剔除所有非数字字符
      const numericOnly = phoneNumber.replace(/\D/g, '');

      // 检查数字是否符合美国电话号码的长度（10位或11位）
      if (numericOnly.length === 10) {
        return numericOnly;
      } else if (numericOnly.length === 11 && numericOnly.charAt(0) === '1') {
        return numericOnly.slice(1);
      } else {
        // 如果不符合规则，返回null或其他错误指示
        return null; // 或者可以根据您的需求返回其他值或抛出异常
      }
    },
  }
}

</script>
<style scoped>
.below-btn{
  
  padding:40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.auto-generate{
  
  
  overflow-y: scroll;
  padding: 20px;
  max-height: 95vh;
  
}
.box{
  display:flex;
  justify-content: space-evenly;
}
.messages{
  width: 400px;
  text-align: center;
}
 .text {
    font-size: 14px;
    text-align: center;
  }
  .text:hover{
    background:#f4f4f4
  }

  .item {
    padding: 10px 0;
  }

  .box-card {
    
    height: 450px
  }
  .btn{
    padding:10px;
  }
  .clearfix{
    padding: 10px;
  }
  .phone-number-box{
    width: 300px;
    text-align: center;
  }
  

</style>