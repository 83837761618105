<template>
  <div class="auto-send">
    <el-upload
      class="upload-demo"
      ref="upload"
      action=""
      :http-request="uploadHandler"
      :show-file-list="false"
      :on-change="onChange"
      :auto-upload="true">
      <el-button slot="trigger" size="small" type="primary">上传任务</el-button>
      
      <div slot="tip" class="el-upload__tip">只能上传 txt文件，格式：目的地----信息内容</div>
    </el-upload>
    <div class="table-top">
        <h4>任务列表</h4>
        <div class="table-top-btn">
          <el-button  type="primary" plain @click="getList">刷新</el-button>
          <el-button  type="primary" plain @click="cancel(1)">取消全部未完成</el-button>
          <el-button  type="primary" plain @click="cancel(2)">取消选中未完成</el-button>
        </div>
    </div>
    <el-table :data="list" v-loading="listLoading" element-loading-text="数据加载中。。。。" border style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column align="center" type="selection" fixed="left"></el-table-column>
        <el-table-column align="center" label="目的地"  prop="customer.phone_number"></el-table-column>
        <el-table-column align="center" label="信息内容"  prop="content"></el-table-column>
        <el-table-column align="center" label="敏感词/相似度拦截"  prop="check_status">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.check_status == 'succeed'">-</span>
              <span v-if="scope.row.check_status == 'similarity'">相似度拦截</span>
              <span v-if="scope.row.check_status == 'sensitive'">敏感触拦截</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态"  prop="status">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.status == 'succeed'">成功</span>
              <span v-if="scope.row.status == 'failed'">失败</span>
              <span v-if="scope.row.status == 'cancel'">已取消</span>
              <span v-if="scope.row.status == 'queue'">排队中</span>

            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="时间"  prop="status">
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.status == 'succeed'">{{scope.row.send_time}}</span>
              

            </div>
          </template>
        </el-table-column>
    </el-table>
    <div class="page">
        <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.size" @pagination="getList" />
      </div>
  </div>
</template>
<script>
import {manySendApi,getAutoListApi, cancelAutoSendMessageApi} from '@/api/messages'
import Pagination from "@/components/Pagination";
import axios from 'axios'
export default {
  name: 'autosend',
  data() {
   return {
    list: [],
    listLoading:false,
    total:1,
    ids:[],
    listQuery:{
      page:1,
      size:10
    }
   }
  },
  components: { Pagination },
  mounted(){
   this.getList()
  },
  methods: {
    cancel(type){
      let params = {
        type:type
      }
      
      if(type == 2){
        params.ids = this.ids
        if(params.ids.length == 0){
          this.$message.error('请选择任务！')
          return;
        }
      }
      cancelAutoSendMessageApi(params).then(res =>{
        if(res.code == 200){
          this.$message.success('操作成功')
          this.getList()
        }
      })
    },
    handleSelectionChange(data){
      this.ids = []
      data.forEach(item => {
        this.ids.push(item.id)
      })
    },
    getList(){
      this.listLoading = true
      getAutoListApi(this.listQuery).then(res =>{
        if(res.code == 200){
          this.listLoading = false
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    uploadHandler(params){
    
      const readFile = new FileReader()
        readFile.readAsText(params.file, 'UTF-8')
        readFile.onload = (e) => {
          this.textarea = e.target.result;
          let arr = this.textarea.split(/[(\r\n)\r\n]+/)
          let list = []

          for(let i=0; i < arr.length; i++){
            let message =  arr[i].split('----')
            if(message[0] == ""){
              break;
            }
            message[0] =  this.formatUSPhoneNumber(message[0])
            if(message[0] == null){
              this.$message.error("号码不正确")
              return
            }
          
            const reg = /^(\(?\d{3}\)?-?)?(\d{3}-?\d{4})$/
            
            if(!reg.test(message[0])){
              this.$message.error('手机号码格式错误')
              return;
            }

            if(message[0].substr(0, 2) !== "+1"){
              var phone_number = "+1" + message[0]
            }
            if(message[0] != ''){
              list.push({'phone_number':phone_number,'messages':message[1]})
            }
            // if(!this.formatUSPhoneNumber(message[0])){
            //   this.$message.error("号码不正确")
            //   return
            // }
            
          }

          manySendApi({data:list}).then(res =>{
            if(res.code == 200){
              this.$message.success('上传成功')
              this.getList()
            }

            
          })
 
          params.onProgress({ percent: 100 }) // 调用 element ui 进度条
          params.onSuccess() // 调用 element ui 上传成功方法
        }


    },
    formatUSPhoneNumber(phoneNumber) {
      // 剔除所有非数字字符
      const numericOnly = phoneNumber.replace(/\D/g, '');

      // 检查数字是否符合美国电话号码的长度（10位或11位）
      if (numericOnly.length === 10) {
        return numericOnly;
      } else if (numericOnly.length === 11 && numericOnly.charAt(0) === '1') {
        return numericOnly.slice(1);
      } else {
        // 如果不符合规则，返回null或其他错误指示
        return null; // 或者可以根据您的需求返回其他值或抛出异常
      }
    },
    onChange(file){
    

    
    }
  }
}
</script>
<style scoped>
.auto-send{
  overflow-y: scroll;
  padding: 20px;
  max-height: 95vh;
  
}
.table-top{
  display: flex;
  justify-content: space-between;
}

</style>