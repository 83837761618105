export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // 交换元素
  }
}

export function generateRandomTextFromArray(inputArray, numRows, enableSimilarityCheck) {
  // 定义用于计算字符串相似度的函数
  function calculateSimilarity(str1, str2) {
    const longer = str1.length > str2.length ? str1 : str2;
    const shorter = str1.length > str2.length ? str2 : str1;
    const longerLength = longer.length;
    if (longerLength === 0) return 1.0;

    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  }

  // 定义编辑距离函数
  function editDistance(str1, str2) {
    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();
    const costs = new Array();
    for (let i = 0; i <= str1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= str2.length; j++) {
        if (i === 0) costs[j] = j;
        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (str1.charAt(i - 1) !== str2.charAt(j - 1)) newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[str2.length] = lastValue;
    }
    return costs[str2.length];
  }

  // 生成随机文本数组
  const randomTextArray = [];
  let attempts = 0; // 记录尝试次数

  while (randomTextArray.length < numRows) {
    const randomIndex = Math.floor(Math.random() * inputArray.length);
    const randomText = inputArray[randomIndex];
    let isSimilar = false;

    if (enableSimilarityCheck) {
      // 检查当前生成文本与前5行的相似度
      if (randomTextArray.length >= 5) {
        isSimilar = randomTextArray
          .slice(randomTextArray.length - 5)
          .some((prevText) => calculateSimilarity(prevText, randomText) > 0.8);
      }
    }

    if (!isSimilar) {
      randomTextArray.push(randomText);
    }

    attempts++; // 增加尝试次数
    // 如果尝试次数过多，退出循环，避免无限循环
    if (attempts > inputArray.length * numRows * 2) {
      break;
    }
  }

  return randomTextArray;
}
