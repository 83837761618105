import axios from 'axios'
import { refreshApi } from "@/api/phoneNumber"
import { Message, MessageBox } from 'element-ui'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['X-Token'] = localStorage.getItem('Token')
    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['X-Token'] = getToken()
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if(res.error === 601){
      return res
    }
    
    if (res.code !== 200 ) {
      if(res.error === 503){
        MessageBox({
          title: "提示",
          message: res.message,
          type: 'warning',
          showClose:false,
          callback: action =>{
            location.href = '/'
          }
        })
      }
      if(res.error !== 502 || res.error === 503){
        Message({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

      
      if (res.error === 501 ) {
        location.href = '/'
      }
      if(res.error === 502){
        let refresh = localStorage.getItem('refresh_token')
        let config =  response.config

        refreshApi({refresh_token:refresh}).then(res=>{
          if (res.code == 200) {

            localStorage.setItem('Token', res.data.token)
            localStorage.setItem('refresh_token',res.data.refresh_token)
            config.headers['X-Token'] = res.data.token
            return axios(config)
            
            
          }
        })
        
      }
      return res
      // return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
