<script>
import { infoApi, bindWebSocketApi, delCustomerAndMessageApi, refreshApi } from "@/api/phoneNumber"
import { createApi, editApi, getListApi,clearRedDotApi, getGroupListApi, setGroupApi, delGroupApi } from "@/api/customer"
import { getCustomerMessageApi,sendApi } from "@/api/messages"
import uimGroup from "@/components/uim/group"
import autoSend from "@/components/autoSend.vue"
import autoGenerate from "@/components/autoGenerate"
export default {
  name: "Imui",
  components: {uimGroup,autoSend,autoGenerate},
  data() {
    return {
      ws: null,
      messageArray:[],
      wsLock: false,
      bindWsLock: false,
      selectGroup: 0,
      groupEditState: 0,
      groupLock:false,
      group:[],
      delGroupid:[],
      groupEdit:{},
      editFromValidateForm:{
        phone_number:'',
        content:''
      },
      appendContactLock: {
        lock: false,
        customer_id:""
      },
      sendBtnLock: {
        add_lock: false,
        lock: false,
        expire_time:0,
      },
      page: 1,
      size: 10,
      UserData: {},
      loading: false,
      edit_from: {
        phone_number: "",
        display_name: ''
      },
      add_from: {
        phone_number: "",
        display_name: ''
      },
      status_timer: null,
      timer: null,
      refresh_timer: null,
      show:false
    };
  },
  render() {
    return (
      <div  style="background: #f4f4f4;" class="qq-lemon-imui">
        <lemon-imui
          style="width:99vw;height:100vh;"
          hide-menu-avatar={true}
          class="lemon-slot"
          user={this.UserData}
          contact-contextmenu={[
            {
              text:'备注',
              click: (e,instance,hide) => {
                const { contact } = instance;
                this.openNotesDrawer("center",contact)
                hide()
              },
            },
            {
              text:'移动分组',
              click: (e,instance,hide) => {
                
                const { contact } = instance;
                this.openGroupDrawer("center",contact)
                hide()
              },
            }
          ]}
          width={900}
          avatar-cricle
          ref="IMUI"
          on={{
            "pull-messages": this.handlePullMessages,
            "change-contact": this.handleChangeContact,
            "menu-avatar-click": this.handleMenuAvatarClick,
            send: this.handleSend,
          }}
          scopedSlots={{
            "sidebar-message-top": (instance) => {
              if(!this.groupLock && this.group.length == 0){
                this.groupLock = true
                getGroupListApi().then(res =>{
                  this.group = []
                  res.data.forEach(item=>{
                    item.is_refresh = 1;
                    this.group.push(item)
                  })
                  this.groupLock = false 
                 
                })
              } 
              return (
                <div class="sidebar-contact-top" style="padding: 0px 10px 0px 0px;display: flex;flex-direction: column;">
                <div style="display: flex;flex-direction: row-reverse; margin-bottom: 8px;">
                
                <el-button style="font-size: 12px;padding:10px 4px 4px 0px;" onClick={()=>{
                  if(this.groupEditState == 0){
                    this.delGroupid = []
                    this.groupEditState = 1;
                  }else{
                    
                    this.groupEditState = 0;
                    if(this.delGroupid.length == 0){
                      return;
                    }
                    delGroupApi({ids:this.delGroupid}).then(res =>{
                      if(this.delGroupid.indexOf(this.selectGroup) != -1){
                        this.selectGroup = 0
                        this.setContact({id:0,is_refresh:1})
                      }
                      
                      this.$message({
                        type: 'success',
                        message: '编辑成功'
                      });
                    })
                  }
                 }}  type="text"> {this.groupEditState == 0 ? '编辑标签':'保存标签'}</el-button>
               
                 <el-button style="font-size: 12px;padding:10px 4px 4px 0px;" type="text" onClick={()=>{
                  this.openAddGroupDrawer('center')
                 }} > 添加标签</el-button>
                </div>
                <div>
                  {this.group.map((item, index) => {
                    return <el-tooltip  class={this.selectGroup == item.id ? 'group-contact group-activate': 'group-contact'} effect="dark" content={item.title} open-delay={1000} placement="top">
                      <el-badge hidden={(item.unread == 0 || this.groupEditState == 1)} value={item.unread} class="item">
                      {(this.groupEditState == 1 && item.id != 0) && <el-button onClick={()=>{
                          
                          let group = []
                          if(item.id == 0){
                            this.$message({
                              type: 'info',
                              message: '该分组无法删除'
                            });
                            return ;
                          }

                          this.group.forEach(value =>{
                            if(value.id != item.id){
                              group.push(value)
                            }else{
                              this.delGroupid.push(item.id)
                            }
                          })
                          
                          this.group = group
                        
                          return false
                        }}  style="padding: 3px;position: absolute;right: -10px;top: -10px;" type="danger" icon="el-icon-delete" circle></el-button>}
                      <div class="el-tooltip top-item" 
                      onClick={()=>{
                        this.setContact(item)
                      } } aria-describedby="el-tooltip-5231" tabindex="0">

                        
                        <div  class="avatar" style="text-align: center;">
                          
                        </div>
                        <div style="font-size: 10px; text-align: center;line-height: 12px;overflow: hidden;"  class="username"> {item.title} </div>
                      </div>
                      </el-badge> 
                    </el-tooltip>;
                  })}
                
                </div>
                 
                </div>
              );
            },
            "sidebar-contact-top": contact => {
              return (
                <div class="sidebar-contact-top">
                  <p>{{contact}}</p>
               
                </div>
              );
            },
          }}
        />
      </div>
    );
  },
  computed: {},
  watch: {},
  created() {
     document.oncontextmenu =function(){
        return false
      }
    
    this.refresh_timer =  setInterval(()=>{
      let refresh = localStorage.getItem('refresh_token')
      refreshApi({refresh_token:refresh}).then(res => {
        if (res.code == 200) {
     
          localStorage.setItem('Token', res.data.token)
          localStorage.setItem('refresh_token',res.data.refresh_token)
        }
      })
    },7000*1000)

    this.show = true
    this.ws = new WebSocket( process.env.VUE_APP_BASE_WEBSOCKET_URL ); 
    this.initEventHandle()

    let status_timer_val = navigator.onLine
    this.status_timer = setInterval(()=>{
      if(!navigator.onLine){
        if(status_timer_val != navigator.onLine){
          // console.log(status_timer_val , navigator.onLine)
          this.$message.error("网络已断开！")
          status_timer_val = false
        }
      }else{
        if(status_timer_val != navigator.onLine){
          this.$message.success("网络已连接！")
          this.ws.send(1)
        }
        status_timer_val = true
      }
    },1000)
  },
  async mounted() {
    
    const IMUI = this.$refs.IMUI;
    await this.getinfo()

   

    // 菜单初始化
    IMUI.initMenus([
      {
        name: "messages",
      },
      {
        name: "customer",
        title: "添加客户",
        unread: 0,
        render: () => {
          return <i class="el-icon-plus" />;
        },
        click: () => {
          this.openDrawer("center")
        },
      },
      {
        name: "custom1",
        title: "删除全部客户信息",
        unread: 0,
        render: () => {
          return <i class="el-icon-delete" />;
        },
        click: () => {
           this.$confirm('此操作将永久删除客户与客户消息, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              delCustomerAndMessageApi().then(res => {
                if(res.code == 200){
                  this.$message({
                    type: 'success',
                    message: '删除成功'
                  });  
                  location.reload()
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除'
              });          
            });
        },
      },
      {
        name: "auto-send",
        title: "自动发送消息",
        unread: 0,
        render: () => {
          return <i class="el-icon-position" />;
        },
        renderContainer: () => {
          return (
            <div class="article">
              <autoSend></autoSend>
            </div>
          );
        },
      },
      {
        name: "auto-generate",
        title: "自动生成",
        unread: 0,
        render: () => {
          return <i class="el-icon-magic-stick" />;
        },
        renderContainer: () => {
          return (
            <div class="article">
              <autoGenerate></autoGenerate>
            </div>
          );
        },
      },
      

    ])

    var ContactsData =  await this.getContacts()

    
    for(let i = 0; i < ContactsData.length;i++){
        if(i == 0){
          // ContactsData[i]['group'] = group
        }
        // ContactsData[i]['unread'] = 0
       if(ContactsData[i]['lastContent'] == ''){
          ContactsData[i]['lastContent'] = IMUI.lastContentRender({type:'event',content:''})
       }else {
          ContactsData[i]['lastContent'] = IMUI.lastContentRender({type:'text',content:ContactsData[i]['lastContent']})
       }
    } 
   

    var contactslength = ContactsData.length
    if(contactslength == 0){
      ContactsData = [
        {
          id: -1,
          displayName: "",
          avatar: "",
          index: "X",
          unread: 0,
          lastSendTime: 1566047865417,
          lastContent: "",
        }
      ]
    }


    await IMUI.initContacts(ContactsData);

    // IMUI.initEmoji(EmojiData);
    if(ContactsData.length!=0){
      IMUI.changeContact(ContactsData[0]['id']);
    }

  
    this.initLimitData()

    IMUI.initEditorTools([
      { 
        name:'text',
        render: () => {
          return <span>当日剩余短信条数: {this.todayNumber}  总剩余条数:{this.countNumber}</span>;
        },
      }
    ]);
  },


  methods: {
   sleep(time){
      var timeStamp = new Date().getTime();
      var endTime = timeStamp + time;
      while(true){
      if (new Date().getTime() > endTime){
        return;
      } 
      }
    },
    async setContact(row){
     
      this.selectGroup = row.id
      if(row.is_refresh == 1){
        var ContactsData =  await this.getContacts()
        var contactslength = ContactsData.length
        if(contactslength == 0){
          ContactsData = [
            {
              id: -1,
              displayName: "",
              avatar: "",
              index: "X",
              unread: 0,
              lastSendTime: 1566047865417,
              lastContent: "",
            }
          ]
        }
        for(let i = 0;i<this.group.length;i++){
          if(this.group[i].id == row.id){
            this.group[i].contacts = ContactsData
            this.group[i].is_refresh = 0
            break;
          }
        }

      }else{
       
        ContactsData = row.contacts

      }
      // console.log(this.$refs.IMUI);
      this.$refs.IMUI.initContacts(ContactsData)
      
      if(ContactsData.length!=0){
        this.$refs.IMUI.changeContact(ContactsData[0]['id']);
        let contacts = this.$refs.IMUI.getContacts()
       
        let list = []
        for(let i = 0; i < this.messageArray.length; i++){
          let is_exist = 0
          for(let j = 0; j< contacts.length; j++){
            
            if(contacts[j].id == this.messageArray[i].customer_id){
              
              this.$refs.IMUI.appendMessage(this.messageArray[i].message)
              is_exist = 1

            }
          }
          if(!is_exist){
            list.push(this.messageArray[i])
          }
          
        }
        this.messageArray = list

        this.$refs.IMUI.getContacts()
      }
    },
    createWebSocket(){
    
      if(!this.wsLock && this.$route.path !== '/'){
        this.wsLock = true
        this.bindWsLock = true
        try{
          clearTimeout(this.timer)
          this.ws = new WebSocket( process.env.VUE_APP_BASE_WEBSOCKET_URL )
          this.initEventHandle()
          setTimeout(()=>{
            this.wsLock = false
          },1000)
          
        }catch(e){
          setTimeout(()=>{
            this.wsLock = false
          },1000)
        }
        
      }
      
    },
    initEventHandle(){
      this.ws.onopen = ()=>{
        //当WebSocket创建成功时，触发onopen事件
        this.ws.send('1')
        this.timer = setInterval(()=>{
          this.ws.send('1')
        },30000)
      }
      this.ws.onmessage = (e)=>{
      //当客户端收到服务端发来的消息时，触发onmessage事件，参数e.data包含server传递过来的数据
      var data = JSON.parse(e.data)

      switch(data.type){
        case 'bind': {
          bindWebSocketApi({'ws_client_id':data.ws_client_id}).then(()=>{
            this.bindWsLock = false
          })
          break;
        }
        case "message-fail":{

          this.sendBtnLock.lock = false
          if(this.appendContactLock.lock){

            if(data.data.customer_id == this.appendContactLock.customer_id){
                this.$message.error('发送失败！')
            }else{
              this.updateMessage({status:'failed',customer_id:data.data.customer_id,error_message:data.data.error_message})
            }
          }else{
            this.updateMessage({status:'failed',customer_id:data.data.customer_id,error_message:data.data.error_message})
          }
          
          break;
        }
        case "message-succeed":{
          this.todayNumber = this.todayNumber - data.data.dec
          this.countNumber = this.countNumber - data.data.dec

          this.$refs.IMUI.initEditorTools([
          { 
              name:'text',
              render: () => {
                return <span>当日剩余短信条数: {this.todayNumber}  总剩余条数:{this.countNumber}</span>;
              },
            }
          ])

          const IMUI = this.$refs.IMUI;

          if(this.sendBtnLock.add_lock){
            var interv_event = setInterval(()=>{ 
            
              if(this.sendBtnLock.expire_time > 0){
                this.sendBtnLock.expire_time -= 1
              }else{
                this.sendBtnLock.lock =false
                this.sendBtnLock.add_lock = false
                clearTimeout(interv_event)
                
              }
            },1000);
          }
          if(data.data.is_auto){
            if(this.selectGroup == data.data.customer.group_id){
              let contacts =  IMUI.getContacts()
             
              let is_add = 1;
              for(let i=0 ;i<contacts.length; i++){
              
                if(contacts[i].id == data.data.customer.id){
                  is_add = 0
                }
              }
            
              if(is_add){
                IMUI.appendContact(data.data.customer);
              }else{
               
                IMUI.appendMessage(data.data.message)
              }

            }else{
              this.messageArray.push(data.data)
            }
            
          }
          if(this.appendContactLock.lock){

            if(data.data.customer_id == this.appendContactLock.customer_id){
              var lastSendTime =  new Date().getTime()
              IMUI.appendContact({
                id: this.appendContactLock.customer_id,
                displayName: this.appendContactLock.displayName,
                lastSendTime: lastSendTime,
                lastContent: this.appendContactLock.lastContent,
              });
              this.$message({ message: "添加成功", type: "success"})
              
              IMUI.closeDrawer()   

              this.appendContactLock.lock = false
            }else{
              this.updateMessage({ status:'succeed',customer_id:data.data.customer_id})
            }

          }else{
            if(!data.data.is_auto){
              this.updateMessage({ status:'succeed',customer_id:data.data.customer_id})
            }
          }
          break;
        }
        case "message-new":{
          const { IMUI } = this.$refs;
          const contact = IMUI.currentContact;

          if( data.to_customer_id !== contact.id){
            var contacts =  IMUI.getContacts()
            
            var is_exist = false
            for(let i=0;i < this.group.length;i++){
               
                if(this.group[i].id == data.group_id){
                  this.group[i].unread = this.group[i].unread +1
                }
            }

            if(this.selectGroup == data.group_id){
              contacts.forEach(item => {
                if(item.id == data.to_customer_id ){
                  is_exist = true
                }
              })
              if(!is_exist){
                IMUI.appendContact({
                  id: data.to_customer_id,
                  displayName: data.displayName,
                  unread:0,
                  lastSendTime: data.send_time *1000,
                  lastContent: data.content,
                });
              }
            }
            
          }
          
          var message = {
            id: this.generateRandId(),
            status: "succeed",
            type: "text",
            sendTime: data.send_time *1000,
            content: data.content,
            toContactId: data.to_customer_id,
            fromUser:{
              id: data.to_customer_id,
              avatar:'',
              displayName: data.displayName
            } 
          }
          
          IMUI.appendMessage(message, true);
          break;
        }
        case "logout":{
         
          if(this.bindWsLock){
            return ;
          }
          this.$message({ message: "您的帐号已在其他地方登录", type: "error"})
          
          this.$router.push({ path: '/' })
          // localStorage.setItem('Token','')
          break;
        }
        case "@heart@":{
          clearTimeout(this.status_timer)
          this.status_timer = setTimeout(()=>{

            this.createWebSocket()
            
            // this.$message({ message: "连接已断开", type: "error"})
            // clearTimeout(this.status_timer)
            // this.$router.push({ path: '/' })
          },50000)
        }
      }
    }
    this.ws.onclose = (e) =>{
      //当客户端收到服务端发送的关闭连接请求时，触发onclose事件
      clearTimeout(this.timer)
      this.createWebSocket()
      
      // this.$router.push({ path: '/' })
 
    }
    this.ws.onerror = (e)=> {
      //如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
      clearTimeout(this.timer)
      this.createWebSocket()

    }
    },
    getByteLen(val) {
      var len = 0;
      for (var i = 0; i < val.length; i++) {
        var a = val.charAt(i);
        if (a.match(/[^\x00-\xff]/ig) != null) {
          len += 2;
        } else {
          len += 1;
        }
      }
      return len;
    },
    initLimitData(){
      var limit = this.UserData.limit
    
      var countNumber = limit.limit_number - limit.count_send_number
      
      var todayNumber = 0 
      if((limit.limit_number + limit.today_send_number) < this.UserData.config.today_send_number){
          todayNumber = limit.limit_number - limit.count_send_number
      }else{
        todayNumber = this.UserData.config.today_send_number - limit.today_send_number
      }
      this.countNumber = countNumber 
      this.todayNumber = todayNumber

    },
    updateMessage(data) {
      const { IMUI } = this.$refs;
      const messages = IMUI.getCurrentMessages();
      const message = messages[messages.length - 1];

  
      if(data.customer_id == message.toContactId){
        var update = message
        update['status'] = data.status
        if(data.status == 'failed'){
          // this.$message.error(data.error_message)
        }
        IMUI.updateMessage(update);
        IMUI.messageViewToBottom();
      }else{
        setTimeout(()=>{
          this.updateMessage(data)
        },1000)
      }
      
      // }
    },
    // 用户信息
    async getinfo(){
      
      
      await infoApi().then(res =>{
        if(res.code == 200){
          this.UserData = {
            id: -res.data.id,
            avatar: "",
            displayName: res.data.phone_number,
            limit:res.data.limit,
            config:res.data.config
          }
        }
        
      })
      
    },
    // 生成随机数
    generateRandId(){
          return Math.random()
            .toString(36)
            .substr(-8);
    },
    // 编辑客户
    editCustomer(contact){
      const { IMUI } = this.$refs;
      editApi(this.edit_from).then(res => {
        if(res.code == 200){
          IMUI.updateContact({
            id:this.edit_from.id,
            displayName:this.edit_from.display_name,
          })
          this.$message({ message: "修改成功", type: "success"})
          IMUI.closeDrawer()   
        }
        
      })
    },
    openAddGroupDrawer(position){
      this.groupEdit = {
        title:"",
      }
      const IMUI = this.$refs.IMUI;
      const params = {
        position,
        render: (contact) => {
          return (
            <div >
              <div style="display: flex;padding: 15px;flex-direction: row-reverse;">
                <span on-click={IMUI.closeDrawer}>关闭</span>
              </div>
              <div style="margin: 20px 50px;">
                <el-input
                  style="margin-bottom: 30px;width:100%;"
                  placeholder="输入标签"
                  v-model={this.groupEdit.title}
                  clearable>
                </el-input>
              
                <el-button loading={this.loading}  type="primary" style="width:100%;margin-bottom:30px;"  on={{
                  click: ()=>{
                    setGroupApi(this.groupEdit).then(res=>{
                      // this.setContact({id:this.selectGroup})
                      getGroupListApi().then(res =>{
                        this.group = []
                        res.data.forEach(item=>{
                          item.is_refresh = 1;
                          this.group.push(item)
                        })
                        
                        IMUI.closeDrawer()
                      })
                    })
                    // console.log(this.groupEdit)
                  }
                }}>提 交</el-button>
              </div>
            </div>
          );
        },
      };
      if (position == "center") {
        params.width = "50%";
        params.height = "50%";
      } else if (position == "rightInside") {
        params.height = "90%";
        params.offsetY = "10%";
      }

      IMUI.openDrawer(params);
    },
    openGroupDrawer(position,contact){
    
      this.groupEdit = {
        id: contact.id,
        title:"",
      }
      const IMUI = this.$refs.IMUI;
      const params = {
        position,
        render: (contact) => {
          return (
            <div >
              <div style="display: flex;padding: 15px;flex-direction: row-reverse;">
                <span on-click={IMUI.closeDrawer}>关闭</span>
              </div>
              <div style="margin: 20px 50px;">
                <el-select
                  style="margin-bottom: 30px;width:100%;"
                  v-model={this.groupEdit.title}
                  default-first-option
                  placeholder="请选择或输入标签">
                  {this.group.map((item,index)=>{
                    return (
                      <el-option key={index} label={item.title} value={item.title}> </el-option>
                    )
                  })}
                  
                  
                </el-select>
                <el-button loading={this.loading}  type="primary" style="width:100%;margin-bottom:30px;"  on={{
                  click: ()=>{
                    setGroupApi(this.groupEdit).then(res=>{
                      
                      this.setContact({id:this.selectGroup,is_refresh:1})
                      getGroupListApi().then(res =>{
                        this.group = []
                        res.data.forEach(item=>{
                          item.is_refresh = 1;
                          this.group.push(item)
                        })
                        IMUI.closeDrawer()
                      })
                    })
                    // console.log(this.groupEdit)
                  }
                }}>提 交</el-button>
              </div>
            </div>
          );
        },
      };
      if (position == "center") {
        params.width = "50%";
        params.height = "50%";
      } else if (position == "rightInside") {
        params.height = "90%";
        params.offsetY = "10%";
      }

      IMUI.openDrawer(params);
    },
    openGroupSettingDrawer(position){

      const IMUI = this.$refs.IMUI;

      const params = {
        position,
        render: (contact) => {
          return (
            <div >
              <div style="display: flex;padding: 15px;flex-direction: row-reverse;">
                <span on-click={IMUI.closeDrawer}>关闭</span>
              </div>
              <div style="margin: 20px 50px;">
                
              </div>
            </div>
          );
        },
      };
      if (position == "center") {
        params.width = "50%";
        params.height = "50%";
      } else if (position == "rightInside") {
        params.height = "90%";
        params.offsetY = "10%";
      }

      IMUI.openDrawer(params);
    },
    // 打开备注窗口
    openNotesDrawer(position,contact){

      this.edit_from.id = contact.id
      this.edit_from.display_name = contact.displayName

      const IMUI = this.$refs.IMUI;

      const params = {
        position,
        render: (contact) => {
          return (
            <div >
              <div style="display: flex;padding: 15px;flex-direction: row-reverse;">
                <span on-click={IMUI.closeDrawer}>关闭</span>
              </div>
              <div style="margin: 20px 50px;">
                <el-input style="margin-bottom: 30px;" v-model={this.edit_from.display_name} placeholder="请输入备注" clearable />
                <el-button loading={this.loading}  type="primary" style="width:100%;margin-bottom:30px;"  on={{
                  click: this.editCustomer
                }}>提 交</el-button>
              </div>
            </div>
          );
        },
      };
      if (position == "center") {
        params.width = "50%";
        params.height = "50%";
      } else if (position == "rightInside") {
        params.height = "90%";
        params.offsetY = "10%";
      }

      IMUI.openDrawer(params);
    },
    // 打开添加用户窗口
    openDrawer(position) {
      this.add_from = {
        phone_number: "",
        content:''
      }
      const IMUI = this.$refs.IMUI;
      const params = {
        position,
        render: (contact) => {
          return (
            <div >
              <div style="display: flex;padding: 15px;flex-direction: row-reverse;">
                <span on-click={IMUI.closeDrawer}>关闭</span>
              </div>
              <div style="margin: 20px 50px;">
                
                <el-form v-model={this.editFromValidateForm} ref="add_from"  label-width="0px" class="demo-dynamic">
                  <el-form-item label="" prop="phone_number">
                    <el-input v-model={this.add_from.phone_number}  placeholder="请输入10位号码，不要+1，也不要1" clearable />
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-show={false}  v-model={this.add_from.display_name} placeholder="请输入备注" clearable />
                  </el-form-item>
                  <el-form-item label="" prop="content">
                    <el-input type="textarea"  rows={4}  v-model={this.add_from.content} placeholder="请输消息内容" clearable />
                  </el-form-item>
                  <el-button loading={this.loading}  type="primary" style="width:100%;margin-bottom:30px;"  on={{
                    click: this.addCustomer
                  }}>发 送</el-button>
                </el-form>
              </div>
            </div>
          );
        },
      };
      if (position == "center") {
        params.width = "50%";
        params.height = "50%";
      } else if (position == "rightInside") {
        params.height = "90%";
        params.offsetY = "10%";
      }
      IMUI.openDrawer(params);
    },
    // 添加客户
    addCustomer(){
      const IMUI = this.$refs.IMUI;

      let number = this.formatUSPhoneNumber(this.add_from.phone_number)
      if(number == null){
        this.$message.error('手机号码格式错误')
        return;
      }

      if(!this.checkNumberLimit(this.add_from)){
        return;
      }
      

      const reg = /^(\(?\d{3}\)?-?)?(\d{3}-?\d{4})$/

      if(!reg.test(number)){
        this.$message.error('手机号码格式错误')
        return;
      }

      if(number.substr(0, 2) !== "+1"){

        var phone_number = "+1" + number

      }

      var contacts =  IMUI.getContacts()

      contacts.forEach( item => {

      })

      createApi({phone_number,content:this.add_from.content}).then(res => {
      
        
        
        var displayName = this.add_from.phone_number
        // if(this.add_from.display_name != ''){
        //   displayName = this.add_from.display_name 
        // }
       
        
        if(res.code == 200){
          this.sendBtnLock = {
            add_lock:true,
            lock:true,
            expire_time: this.UserData.config.send_message_limit
          }
          this.appendContactLock = {
            lock:true,
            customer_id:res.data.id,
            displayName:displayName,
            lastContent:this.add_from.content
          }  
          
          
        }else{
          IMUI.closeDrawer()
         
          IMUI.changeContact(res.data.id)

          setTimeout(()=>{
            // IMUI.setEditorValue(this.add_from.content)
            var contact = IMUI.currentContact
            var sendTime =  new Date().getTime()
            var message = {
              id: this.generateRandId(),
              status: "going",
              type: "text",
              sendTime: sendTime,
              content: this.add_from.content,
              toContactId:contact.id,
              fromUser:{
                id:this.UserData.id,
                avatar:"",
                displayName:this.UserData.phone_number
              }
            }
     
            IMUI.appendMessage(message)
            this.handleSend(message,()=>{},null)
          },300)

        }
      })
    },
    // 获取客户
    async getContacts(){
      var res =  await getListApi({group_id:this.selectGroup})
      return res.data.list
    },
    // 用户修改事件
    handleChangeContact(contact, instance) {
      if(contact.id == -1){
        return ;
      }
      instance.updateContact({
          id: contact.id,
          unread: 0,
      });
      for(let i = 0;i <this.group.length;i++){
       
        if(this.group[i].id == this.selectGroup){
          this.group[i].unread = this.group[i].unread - contact.unread
        }
      }


      instance.closeDrawer();
      clearRedDotApi({id:contact.id,page:1,size:1}).then(res => {
        
      })
      
      
    },
    // 头像点击事件
    handleMenuAvatarClick() {

    },
    // 拉取信息
    handlePullMessages(contact, next,instance) {
      const { IMUI } = this.$refs;
      var page = (instance.getMessages(instance.currentContactId).length / this.size) +1

      getCustomerMessageApi({id:contact.id,page:page,size:this.size}).then(res=>{
        var data = []
        
        if(res.data.total == 0){
          if(contact.id == -1){
            var content = ''
          }else{
            var content = '"可以向他发送短信啦。"'
          }

          data.push({
            id: this.generateRandId(),
            status: "succeed",
            type: 'event',
            sendTime: 1566047865417,
            content: content,
            toContactId: contact.id,
            fromUser: {}
          })
        }

       
        for(let i = 0 ;i<res.data.list.length;i++){
          var fromUser = {}
          if(res.data.list[i].is_customer_send == 1){
            fromUser = {
              id: contact.id,
              avatar: "",
              displayName:contact.displayName
            }
          }else{
            
            fromUser = {
              id: this.UserData.id,
              avatar: '',
              displayName: this.UserData.displayName
            }
          }
          
          data.push({
            id: res.data.list[i].id,
            status: res.data.list[i].status,
            type: res.data.list[i].type,
            sendTime: res.data.list[i].send_time*1000,
            content: res.data.list[i].content,
            toContactId: res.data.list[i].to_customer_id,
            fromUser: fromUser
          })
      
          
        }
        var isend = false
        
        if((instance.getMessages(instance.currentContactId).length + data.length) >= res.data.total) {
          isend = true
        }
        this.page += this.page
        next( data, isend);
      }) 
    },
    // 检测短信
    checkNumberLimit(message){
      if(this.countNumber  <= 0) {
        this.$message.error('发送次数不足！')
        return false;
      }
      if(this.todayNumber  <= 0) {
        this.$message.error('今日发送次数已用完！')
        return false;
      }
      
      // if(this.getByteLen(message.content) >= 160){
      //   this.$message.error('最高可发送 160 个字符')
      //   return false
      // }
      if(this.sendBtnLock.lock){
        this.$message.error('请在 '+this.sendBtnLock.expire_time+' 秒后发送！')
        return false
      }
      return true
    },
    formatUSPhoneNumber(phoneNumber) {
      // 剔除所有非数字字符
      const numericOnly = phoneNumber.replace(/\D/g, '');

      // 检查数字是否符合美国电话号码的长度（10位或11位）
      if (numericOnly.length === 10) {
        return numericOnly;
      } else if (numericOnly.length === 11 && numericOnly.charAt(0) === '1') {
        return numericOnly.slice(1);
      } else {
        // 如果不符合规则，返回null或其他错误指示
        return null; // 或者可以根据您的需求返回其他值或抛出异常
      }
    },
    // 发送短信
    handleSend(message, next, file) {

      if(!this.checkNumberLimit(message)){
        this.$refs.IMUI.removeMessage(message.id)
        setTimeout(()=>{
          this.$refs.IMUI.setEditorValue(message.content)
        },100)
        
        return false;
      }

      sendApi({'content': message.content, 'to_customer_id':message.toContactId}).then(res => {
        this.sendBtnLock = {
          lock:true,
          expire_time: this.UserData.config.send_message_limit
        }
        var interv_event = setInterval(()=>{ 
            
          if(this.sendBtnLock.expire_time > 0){
            this.sendBtnLock.expire_time -= 1
          }else{
            this.sendBtnLock.lock =false
            clearTimeout(interv_event)
                
          }
        },1000);

        next()
      })
    },
  },
  // 销毁函数
  beforeDestroy(){
    this.ws.close()
    clearTimeout(this.status_timer)
    clearTimeout(this.timer) 
    clearTimeout(this.refresh_timer)
  }
};
</script>
<style scoped>
>>>.lemon-sidebar{
  
  width:280px;
}
.group-contact{
  width:62px;
  padding:6px;
  margin: 0px  0px  20px  10px;
  border: solid #d9d9d9 1px;
  
}
.group-activate{
  background:#d9d9d9;
}

/* 在Chrome浏览器下 */
>>>.lemon-drawer input::-webkit-outer-spin-button,
>>>.lemon-drawer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* 在Firefox浏览器下 */
>>>.lemon-drawer input[type="number"]{
   -moz-appearance: textfield;
}

.lemon-badge__label {
  opacity: 0;
}

>>>.lemon-editor__inner {
  box-shadow: rgba(185, 189, 184, 1.0) 0px 0px 2px !important;
}

>>>.lemon-messages {
  box-shadow: rgba(185, 189, 184, 1.0) 0px 0px 2px !important;
}

>>>.lemon-container__title {
  box-shadow: rgba(185, 189, 184, 1.0) 0px 0px 2px !important;
}

>>> .lemon-container{
  background:white
}

>>> .lemon-sidebar{
  background:white
}

>>> .lemon-contact{
  background:white
}

>>> .lemon-drawer{
  background:white
}



</style>

